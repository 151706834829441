import axios from 'axios'

const BASE_URL = process.env.REACT_APP_myd2si_api_url;
const URL_TIMEOUT = 10000;


function requester(jwtToken) {
  return axios.create({
    timeout: URL_TIMEOUT,
    headers: {
      Authorization: jwtToken
    }
  });
}

class MyRevolveApi {
  get_user_image(user_id) {
    return BASE_URL + `/v1/users/${user_id}/image`
  }

  get_user(user_id, jwtToken, callback) {
    requester(jwtToken).get(`${BASE_URL}/v2/users/${user_id}`)
      .then((response) => callback(response.data))
      .catch(err => console.log('err: ', err));
  }

  get_user_profile(user_id, jwtToken, callback) {
    requester(jwtToken).get(`${BASE_URL}/v2/users/${user_id}/profile`)
      .then((response) => callback(response.data))
      .catch(err => console.log('err: ', err));
  }

  patch_user_profile(user_id, jwtToken, document) {
    return requester(jwtToken).patch(`${BASE_URL}/v2/users/${user_id}/profile`, document)
  }
}

export default new MyRevolveApi();
