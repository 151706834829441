import React, { Component } from 'react'
import Menu from "./Menu"
import UserProfilePage from './/UserProfilePage'


class MainPage extends Component {
    render() {
        return (
            <div>
                <Menu user_id={this.props.user_id}
                    jwtToken={this.props.jwtToken}
                    select_acceuil={this.select_acceuil}
                    select_profile={this.select_profile}
                    signOut={this.props.signOut} />
                <div className="container">
                    <br /><br /><br />
                    <UserProfilePage user_id={this.props.user_id} jwtToken={this.props.jwtToken} />
                </div>
            </div>
        )
    }
}

export default MainPage;