import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import {COGNITO_CONFIG} from './utils/CognitoConfig';
import 'bootstrap/dist/css/bootstrap.min.css';

COGNITO_CONFIG.aws_user_pools_web_client_id = process.env.REACT_APP_aws_user_pools_web_client_id;
COGNITO_CONFIG.oauth.redirectSignIn = process.env.REACT_APP_redirectSignIn;
COGNITO_CONFIG.oauth.redirectSignOut = process.env.REACT_APP_redirectSignOut;

Amplify.configure(COGNITO_CONFIG);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
