import React from 'react'
import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

function SendingButton(props) {
    return (
        <Button variant={props.variant} disabled={props.sending} onClick={props.onClick}>
            {props.sending && <Spinner animation="border" size="sm" style={{marginRight: '6px'}}/>}
            {props.value || props.children}
        </Button>)
}

export default SendingButton
