import React, { Component } from 'react'
import Button from 'react-bootstrap/Button'

class LoginPage extends Component {
    render() {
        return (
            <div>
                <img src="./logo512.png" className="App-logo" alt="logo" />
                <p>Mon profil collaborateur Devoteam Revolve.</p>
                <Button variant="secondary" onClick={this.props.singIn}>Se connecter</Button>
            </div>
        )
    }
}

export default LoginPage;