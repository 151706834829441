import React, { Component } from 'react';
import './App.css';
import { Auth } from 'aws-amplify'
import LoginPage from './components/LoginPage'
import MainPage from './components/MainPage'


function singIn() {
  Auth.federatedSignIn({ provider: 'Google' })
}

function signOut() {
  Auth.signOut()
    .then(data => console.log(data))
    .catch(err => console.log(err))
}



class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user_id: null,
      user_email: null,
      jwtToken: null
    }
    Auth.currentAuthenticatedUser()
      .then(user => {
        let email = user.attributes['email']
        let id = email.substring(0, email.indexOf('@'))
        let jwtToken = user.signInUserSession.idToken.jwtToken
        this.setState({ user_id: id, user_email: email, jwtToken: jwtToken })
      })
      .catch(err => console.log(err))
  }

  render() {
    if (this.state.user_id == null) {
      return (
        <div className="App">
          <header className="App-header">
            <LoginPage singIn={singIn} />
          </header>
        </div>
      )
    } else {
      return (
        <div className="App">
            <MainPage user_id={this.state.user_id} jwtToken={this.state.jwtToken} signOut={signOut}/>
        </div>
      );
    }
  }
}

export default App;
