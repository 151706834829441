export const COGNITO_CONFIG = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_ofdSwjKNx",
    "aws_user_pools_web_client_id": "26f3ju93hsmcsgpug8ugkk5fhj",
    "oauth": {
        "domain": "myrevolve.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://intranet.revolve.team",
        "redirectSignOut": "https://intranet.revolve.team",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};
